<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconLeftArrow',
  mixins: [Icons],
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 23.17 37.83"
    aria-labelledby="LeftArrowIcon"
    role="presentation"
  >
    <title id="LeftArrowIcon" lang="en">
      Left Arrow Icon
    </title>
    <g :fill="color">
      <polygon
        class="cls-1"
        points="23.17 2.92 20.25 0 0 19.17 20.08 37.83 23.17 35 5.76 18.92 23.17 2.92"
      />
    </g>
  </svg>
</template>
